<script setup>
    
</script>

<template>
  <div id="app">
    <div id="status-bar">
      <button class="buttons">Главная</button>
      <button class="buttons">Обо мне</button>
      <button class="buttons">Пожертвовать</button>
      <button class="buttons">Ссылки</button>
      <button class="buttons">Вход #1</button>
      <button class="buttons">Вход #2</button>
      <button class="buttons">Вход #3</button>
    </div>
    <div class="content">
         <a href="https://my.hosting-minecraft.pro/aff.php?aff=5889" target="_blank">Good hosting</a><br><br>
         Keyboard X<br><br>
         (-)<br><br>
         Vue.js X<br>
         Rust X<br>
    </div>
  </div>
</template>


<style>
/* Основные стили */
body {
  background-color: #141414;
  color: #fff;
}

/* Кнопки */
.buttons {
  background-color: #333331;
  border: none;
  color: #fff;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

.buttons:focus {
  outline: none;
  background-color: #141414;
  box-shadow: 0 0 0 2px #4CAF50;
}

.buttons:hover {
  background-color: #141414;
}

.avatars {
  display: flex;
  justify-content: center;
  gap: 120px;
}

.avatar {
  width: 200px;
  height: 200px;
}

#status-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
}

a {
 color: wheat;
}

@media only screen and (max-width: 767px) {
  
}
</style>
